<template>
  <div>
    <defaultFieldTypes
      v-if="!hiddenArray.includes(operator)"
      :fieldAttributes="fieldAttributes"
      :field="field"
      :templateContent="result"
      v-on="$listeners"
      :value="value"
      :hideDetails="hideDetails"
    ></defaultFieldTypes>
  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
import options from "@/cfg/options.json";

export default {
  components: { defaultFieldTypes },
  props: ["result", "fieldAttributes", "field", "value","hideDetails"],
  data() {
    return {
      hiddenArray: options.hiddenArray,
    };
  },
  watch: {
    "result.valueLine.operator": function (operator) {
      console.log("get operators");

      if (this.hiddenArray.includes(operator)) {
        // clear value
        this.$emit("input", "");
        this.$emit("change", "");
      }
    },
  },
  computed: {
    operator() {
      return this.result.valueLine.operator;
    },
  },
};
</script>